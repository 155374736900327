import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import PaymeTheme from "../../assets/styling/payme-theme";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledCashFlowPayment = styled.div `
  text-align: center;
  background-color: ${PaymeTheme.footerBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .loading-wrapper {
     margin-top: ${paymeTheme.paddingRemLg};
  }
  
  .cashflow-button {
    background-color: transparent;
    border-radius: 10px;
    color: ${PaymeTheme.colorWhite};
    font-size: ${PaymeTheme.fontSizeRemH5};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.5rem;
    width: 100%;
    max-width: 12.125rem;
    margin: 0.625rem auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: 2px solid ${PaymeTheme.colorWhite};
    
    &:disabled {
      background-color: ${PaymeTheme.lightGrey};
      color: ${PaymeTheme.colorBlack};
      border-color: ${PaymeTheme.lightGrey};
    }
    
    &:focus {
      outline: none;
    }
  }
  
  ${media.desktopMedium} {
  margin: 0;
  }
  `;

export { StyledCashFlowPayment } ;
