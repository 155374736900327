import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";


const StyledCommunicationWrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;

  .payment-wrapper {
    flex-grow: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .wrapper {
      flex-grow: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      div {
        flex-grow: 1;
      }
    }
  }
  
  ${media.desktopMedium} {
    margin: 0;
  }
`;

export { StyledCommunicationWrapper } ;
