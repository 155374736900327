export const BASE_API_URL = "https://django-boilerplates.tremend.ro/api";

export const NO_MACHINE_ERROR_BODY = "Please use another one!";
export const NO_MACHINE_ERROR_TITLE = "This machine isn't set up yet";

export const UNIT_NOT_REGISTERED = "Unit not registered";

export const ERROR_MESSAGE_BODY = "Please try again later or use cash...";
export const VENDING_ERROR_MESSAGE_BODY = "Please try again later or use cash...";
export const ERROR_MESSAGE_TITLE = "Contactless currently unavailable";

export const DENIED_ERROR_MESSAGE_TITLE = "Sorry your payment has been declined";
export const DENIED_ERROR_MESSAGE_BODY = "Please try again...";

export const SUCCESS_MESSAGE_TITLE = "Thank you!";
export const SUCCESS_MESSAGE_BODY = "Payment successful";
export const VENDING_SUCCESS_MESSAGE_BODY = "Payment successful";

export const PENDING_PAYMENT_TITLE = "Your payment is processing";
export const PENDING_PAYMENT_BODY = "Please, wait for a few minutes";

export const TIMEOUT_PAYMENT_TITLE = "Sorry your connection has timed out";
export const TIMEOUT_PAYMENT_BODY_BATTERY = "Please, re-tap the machine to try again";
export const TIMEOUT_PAYMENT_BODY_MAINS = "Please re-scan the QR code and try again";
export const TIMEOUT_PAYMENT_BODY = "Please try again...";

export const SYSTEM_ERROR_BODY = "Please try again!";
export const SYSTEM_ERROR_TITLE = "System error, no payment taken"

export const MAKE_SELECTION_VENDING = "Please make your selection";
export const INVALID_SELECTION_VENDING = "Invalid selection, please select";

export const DIGITS_HELPER_ARRAY = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "X", "0", "OK"];
export const LETTERS_REG_EXP = /[a-zA-Z]/;
export const DIGITS_REG_EXP = /\d/;

export const INVALID_PAYMENT_VALUE = -1;

export const LOYALTY = "Loyalty";
export const PROFILE = "Profile";
export const WINNINGS = "Winnings";
export const WHERE_TO_PLAY = "Where to play";
export const BALANCE = "Balance";
export const SCAN = "Scan";
export const LIVE_SIMULATION = "Live Simulation";
export const FAILED_PAYMENT = "Payment Failed";
export const SUCCESS_PAYMENT = "Payment Success";


export const POUND_KEY = "£";
