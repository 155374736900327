import React, {FC, useContext, useEffect, useState} from "react";
import { StyledCommunicationWrapper } from "./styles";
import UnitDetails from "../UnitDetails";
import MessageNotification from "../Message";
import PaymentContainer from "../SqPayment/PaymentContainer";
import {PaymentDetails, TransactionPaymentStatusDetails, UnitDetailsInterface} from "../../@types";
import {useMessagesRetriever} from "../../hooks/generalHooks/setMessagesOnSocketStatus";
import CashFlowPayment from "../CashFlowPayment";
import {TransactionStatusDetails, UnitIdContext} from "../Containers/UnitsContainer";
import LoadingComponent from "../Loading";
import Footer from "../Footer";

interface Props {
  unitDetails: UnitDetailsInterface,
  handleNonce: (nonce: PaymentDetails) => void;
  cashflowPaymentLink: string;
  userId: string;
  showGeneralLoading: boolean;
  demo?: boolean;
}

const PoolTableCommunication: FC<Props> = ({unitDetails, handleNonce, cashflowPaymentLink, userId, showGeneralLoading, demo = null}) => {
  const CATEGORY_UNIT = unitDetails && unitDetails.hostType && unitDetails.hostType.name;
  const POOL_PAYMENT_VALUE = 1;
  const unitId = useContext(UnitIdContext);
  const transactionPaymentStatusDetails = useContext<TransactionPaymentStatusDetails>(TransactionStatusDetails);
  const [isPayButtonPressed, setIsPayButtonPressed] = useState(false);

  const [
    isDisplayedPaymentButton,
    isDisplayedLoading,
    messageType,
    messageTitle,
    messageBody
  ] = useMessagesRetriever(unitDetails);

  const [paymentValue, setPaymentValue] = useState(unitDetails && unitDetails.defaultTransactionAmount ? `${unitDetails.defaultTransactionAmount}` : 1.00);

  const onChangePaymentValueHandler = (value: string) => {
    setPaymentValue(value);
  };

  useEffect(() => {
    if (unitDetails && unitDetails.creditType === "HOST_SING_VAL") {
      unitDetails.defaultTransactionAmount && setPaymentValue(unitDetails.defaultTransactionAmount.toString());
    }
  }, [unitDetails, unitId, paymentValue]);

  const onClickPayBtnHandler = () => {
    if (unitDetails.paymentProcessor === "CASHFLOWS" &&
      !!paymentValue &&
      parseFloat(paymentValue) <= unitDetails.hostType.maxTransactionAmount) {
      handleNonce({
        unitId,
        nonce: '',
        amount: parseFloat(paymentValue),
        currency: "GBP",
        paymentProvider: null,
        paymentProcessor: 'CASHFLOWS',
      });
      setIsPayButtonPressed(true);
    }
  };

  return (
    <StyledCommunicationWrapper>
      <UnitDetails categoryUnit={CATEGORY_UNIT}
                   unitDetails={unitDetails}
                   paymentValuePerUnit={POOL_PAYMENT_VALUE}
                   isVending={false}
                   transactionPaymentStatusDetails={transactionPaymentStatusDetails}
                   photoUrl={unitDetails.photoUrl}
                   isPayButtonPressed={isPayButtonPressed}
                   userId={userId}
                   onChangePaymentValueHandler={onChangePaymentValueHandler}
                   isLoading={showGeneralLoading}
                   cashflowPaymentLink={cashflowPaymentLink}
      />
      <div className="payment-wrapper">
        {
          unitDetails.status === 'READY' && !userId && !showGeneralLoading &&
          ['success', 'warning', 'timeout', 'error', 'denied'].indexOf(transactionPaymentStatusDetails.transactionStatus) === -1 &&
          ['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1 &&
          (
            <div className="wrapper">
              {unitDetails.paymentProcessor === "SQUARE" ?
                <PaymentContainer
                  isDisplayedPaymentButton={isDisplayedPaymentButton}
                  paymentValuePerUnit={POOL_PAYMENT_VALUE}
                  categoryUnit={CATEGORY_UNIT}
                  isVending={false}
                  isDisplayedLoading={isDisplayedLoading}
                  handleNonce={handleNonce}
                />
                :
                <CashFlowPayment cashflowPaymentLink={cashflowPaymentLink}
                                paymentValue={paymentValue}
                                unitDetails={unitDetails}
                                demo={demo}
                                onClickPayBtnHandler={onClickPayBtnHandler}
                />
              }
            </div>
          )
        }
        {
          !userId &&
          !showGeneralLoading &&
          ['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) !== -1 &&
          <MessageNotification messageType={messageType}
                               messageTitle={messageTitle}
                               messageBody={messageBody}/>
        }
        {transactionPaymentStatusDetails.transactionStatus !== '' &&
        !showGeneralLoading &&
        ['success', 'warning', 'timeout', 'error', 'denied'].indexOf(transactionPaymentStatusDetails.transactionStatus) !== -1 &&
        <MessageNotification messageType={transactionPaymentStatusDetails.transactionStatus}
                             messageTitle={transactionPaymentStatusDetails.transactionStatusTitle}
                             messageBody={transactionPaymentStatusDetails.transactionStatusBody}/>
        }
        {showGeneralLoading && <LoadingComponent displayLoadingMessage={false}/>}
      </div>
      <Footer/>
    </StyledCommunicationWrapper>
  );
};

export default PoolTableCommunication;
