import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import PaymeTheme from "../../assets/styling/payme-theme";


const StyledFooter = styled.div `
  text-align: center;
  background-color: ${PaymeTheme.footerBackground};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 3.75rem;

  svg {
    width: 100%;
    height: auto;
  }
  
  
  ${media.desktopMedium} {
    margin: 0;
  }
`;

export { StyledFooter } ;
