import styled from 'styled-components';
import paymeTheme from '../../../assets/styling/payme-theme';



const StyledNoUnit= styled.div `
  height: ${paymeTheme.heightXLg};
  display: flex;
  flex-direction: column;
  
  .placeholder-wrapper {
    width: 100%;
    height: ${paymeTheme.heightLg};
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
      height: ${paymeTheme.heightMd}!important;
      width: ${paymeTheme.widthMd}!important;
    }
  }
  `;

export { StyledNoUnit } ;
