import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from '../../assets/styling/payme-theme';


const StyledLoading = styled.div `
  text-align: center;
  padding-top: ${paymeTheme.paddingRemLg};
  background-color: ${paymeTheme.footerBackground};
  min-height: 7.5rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: ${props => props.theme.displayLoadingMessage ? "unset" : "center"};
  align-items: center;
  img {
    width: 2.5rem;
    height: auto;
    border: none;
    border-radius:50%;
    animation:spin 1100ms ease-in-out infinite;
    -webkit-animation:spin 1100ms ease-in-out infinite;
  }

  .loading-svg-wrapper {
    padding: ${props => props.theme.displayLoadingMessage ? `${paymeTheme.paddingRemLg} 0` : "0"};
    min-height: ${props => props.theme.displayLoadingMessage ? "7.5rem" : "unset"};
  }

  .loading-information {
    height: 55vh;
    font-size: ${paymeTheme.fontSizeRemNormal};
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    p {
      position: relative;
      bottom: 2rem;
    }
  }
  
  @keyframes spin {
    to {
      transform: scale(2) rotate(180deg);
      -webkit-transform: scale(2) rotate(180deg);
    }
  }

  }
  ${media.desktopMedium} {
  margin: 0;
  }
  `;

export { StyledLoading } ;
