import styled from 'styled-components';


const DashboardStyles = styled.div(props => {
  const iconSize = 3;
  const textSize = 1.7;
  const winningsSize = 1.7;
  const balanceIconSize = 150;

  return `
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items:center;
  overflow-y: hidden;
  overflow-x: hidden;

  .options-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-areas: 'loyalty profile' 'winning where';
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .loyalty {
    width: 100%;
    height: 100%;
    grid-area: loyalty;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    background-color: #2B6BD2;
    padding-top: 4rem;
    cursor: pointer;
  }

  .profile {
    width: 100%;
    height: 100%;
    grid-area: profile;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    background-color: #349BF0;
    padding-top: 4rem;
    cursor: pointer;
  }

  .winnings {
    width: 100%;
    height: 100%;
    grid-area: winning;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    background-color: #152C7D;
    padding-top: 4rem;
    cursor: pointer;

    .amount {
      font-size: ${winningsSize}rem;
    }
  }

  .where {
    width: 100%;
    height: 100%;
    grid-area: where;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    background-color: #8FD150;
    padding-top: 4rem;
    cursor: pointer;

    .text {
      max-width: 90%;
      text-align: center;
    }

  }

   .text-icon {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      justify-self: center;

      .icon {
        font-size: ${iconSize}rem;
        color: #DA1B62;
      }

      .text {
        font-size: ${textSize}rem;
      }
    }

      .balance-circle {
        width: ${balanceIconSize}px;
        height: ${balanceIconSize}px;
        border-radius: 100%;
        background-color: #152C7D;
        position: absolute;
        border: 10px solid rgba(171, 215, 249, .6);
        -webkit-background-clip: padding-box; /* for Safari */
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items:  center;
        position: absolute;
        bottom:50%;


        .amount {
          font-size: ${textSize}rem;
        }

        .balance {
          font-size: 1rem;
        }
      }

       .scan-circle {
          position: absolute !important;
          bottom: 10px;
          padding: .3rem;
          border-radius: 5%;
          background-color: #FFFFFF;
          color: white;
          border: 10px solid rgba(171, 215, 249, .6);
          -webkit-background-clip: padding-box; /* for Safari */
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items:  center;

          .icon {
            color: #131111;
          }

          .text {
            color: #131111;
            font-size: 1rem;
          }
        }
}


`
});

export { DashboardStyles } ;
