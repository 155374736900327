import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import PaymeTheme from "../../assets/styling/payme-theme";


const StyledPaymentForm = styled.div `
  background-color: ${PaymeTheme.footerBackground};
  padding: 3.125rem;
  
  /* Square form styles */

  #form-container {
    width: 100%;
  }
  
  .wallet-button {
    background-color: transparent;
    min-height: 3.5rem;
    width: 100%;
    max-width: 12.125rem;
    margin: 10px auto;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    cursor: pointer;
    border: 2px solid ${PaymeTheme.colorWhite};
    
    &:focus {
      outline: none;
    }
  }

  /* Customize the Google Pay button */
  .google-pay-button {
    padding: 0.688rem 1.5rem;
    background-origin: content-box;
    background-size: contain;
    background-image: url(https://www.gstatic.com/instantbuy/svg/dark_gpay.svg);
  }
  
  /* Customize the Apple Pay on the Web button */
  .apple-pay-button {
    margin: auto;
    background-color: ${PaymeTheme.colorWhite};
    background-size: 100% 60%;
    min-height: 4rem;
    max-width: 6.5rem;
    border: none;
  }

  
  @supports (-webkit-appearance: -apple-pay-button) { 
    .apple-pay-button {
        display: inline-block;
    }
    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }
  }

  @supports not (-webkit-appearance: -apple-pay-button) {
      .apple-pay-button {
          display: inline-block;
          padding: 0px;
          box-sizing: border-box;
          max-height: 4rem;
      }
      .apple-pay-button-white {
          background-image: -webkit-named-image(apple-pay-logo-black);
          background-color: ${PaymeTheme.colorWhite};
      }
  }

  ${media.desktopMedium} {
    margin: 0;
  }
  `;

export { StyledPaymentForm } ;
