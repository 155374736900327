import styled from 'styled-components';

const FruitMachineStyles = styled.div(props => {

  return `
    .new-flow {
      width: 100%;
      height: 100vh !important;
      min-width: 280px;
      min-height: 653px;
      overflow-y: hidden;
      overflow-x: hidden;
    }
`
});

export { FruitMachineStyles } ;
