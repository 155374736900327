import React, { FC, useContext, useEffect } from "react";
import { StyledCashFlowPayment } from "./styles";
import MessageNotification from "../Message";
import { TransactionStatusDetails } from "../Containers/UnitsContainer";
import { TransactionPaymentStatusDetails, UnitDetailsInterface } from "../../@types";
import { useMessagesRetriever } from "../../hooks/generalHooks/setMessagesOnSocketStatus";
import LoadingComponent from "../Loading";

interface Props {
  cashflowPaymentLink: string;
  onClickPayBtnHandler: () => void;
  paymentValue: string;
  unitDetails: UnitDetailsInterface;
  demo: boolean
}

const CashFlowPayment: FC<Props> = ({
                                      cashflowPaymentLink,
                                      onClickPayBtnHandler,
                                      paymentValue,
                                      unitDetails,
                                      demo
                                    }) => {
  const transactionPaymentStatusDetails = useContext<TransactionPaymentStatusDetails>(TransactionStatusDetails);
  const [
    isDisplayedPaymentButton,
    isDisplayedLoading,
    messageType,
    messageTitle,
    messageBody
  ] = useMessagesRetriever(unitDetails);
  const defaultTransactionAmount = unitDetails.defaultTransactionAmount || 1.00;

  const onClickHandler = () => {
    onClickPayBtnHandler();
  };

  /**
   * When the payment link is received from the backend, the UI client redirects to the payment link
   *
   * The demo logic is only here to disable the redirection
   */
  useEffect(() => {
    if (cashflowPaymentLink !== "" && !demo) {
      window.location.href = cashflowPaymentLink;
    }
  }, [cashflowPaymentLink, demo]);

  return (
    <div>
      {transactionPaymentStatusDetails.transactionStatus === '' && cashflowPaymentLink === "" &&
      ['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1 &&
      <StyledCashFlowPayment>
        <button className="cashflow-button" onClick={onClickHandler}
                disabled={paymentValue === ""}>Pay
        </button>
      </StyledCashFlowPayment>
      }

      {!(transactionPaymentStatusDetails.transactionStatus === '' && cashflowPaymentLink === "" &&
        ['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1) &&
      <div className="loading-wrapper"><LoadingComponent displayLoadingMessage={false}/></div>
      }
      {['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) !== -1 &&
      <MessageNotification messageType={messageType}
                           messageTitle={messageTitle}
                           messageBody={messageBody}/>}
      {
        transactionPaymentStatusDetails.transactionStatus !== '' &&
        (
          <MessageNotification messageType={transactionPaymentStatusDetails.transactionStatus}
                               messageTitle={transactionPaymentStatusDetails.transactionStatusTitle}
                               messageBody={transactionPaymentStatusDetails.transactionStatusBody}/>
        )
      }
    </div>
  );
};

export default CashFlowPayment;
