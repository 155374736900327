import styled from 'styled-components';
import paymeTheme from '../../../assets/styling/payme-theme';


const GeneralStyles= styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  .machines-wrapper {
      height: 100%;
  }
  .env {
    position: absolute;
    bottom: 5px;
    color: ${paymeTheme.colorYellow};
    text-transform: capitalize;
    font-size: ${paymeTheme.fontSizeRemNormal};

    &.env-right {
      right: ${paymeTheme.paddingRemMd};
    }

    &.env-left {
      left: ${paymeTheme.paddingRemMd};
    }
  }
`;

export { GeneralStyles } ;
