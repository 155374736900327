import React, {FC, Fragment, useEffect, useState,} from "react";
import { GlobalStyle } from "../../../assets/styling/payme-global-styles";
import Footer from "../../Footer";
import UnitsContainer from "../UnitsContainer";
import { GeneralStyles } from "./styles";

const initialHeight = window.innerHeight;

const Main: FC = () => {
  const [keyboard, setKeyboard] = useState(false);

  const apiBaseUrlByEnvironment = `${process.env.REACT_APP_BASE_API_URL}`;
  const environmentTypeName = apiBaseUrlByEnvironment.split('//')[1].split('.')[0];

  useEffect(() => {
    document.addEventListener("keypress", checkIfPressedKeyIsEnter);
    return () => {document.removeEventListener("keypress", checkIfPressedKeyIsEnter)};
  }, []);

  useEffect(() => {
    window.addEventListener('resize', checkIfKeyboardOpened);
    return () => {
      document.removeEventListener("resize", checkIfKeyboardOpened)
    };
  }, []);

  useEffect(() => {
    if (keyboard) {
      document.documentElement.style.setProperty('overflow', 'auto');
      const metaViewport = document.querySelector('meta[name=viewport]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 'height=' + initialHeight + 'px, width=device-width, initial-scale=1.0');
      }
    } else {
      const metaViewport = document.querySelector('meta[name=viewport]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
      }
    }
  }, [keyboard]);

  const checkIfPressedKeyIsEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.querySelectorAll("input").forEach(el => el.blur());
    }
  };

  const checkIfKeyboardOpened = () => {
    // If the current active element is a text input, we can assume the soft keyboard is visible.
    if (document.activeElement) {
      document.activeElement.tagName === 'INPUT' ? setKeyboard(true) : setKeyboard(false);
    }
  };

  return (
      <Fragment>
        <GeneralStyles>
          <GlobalStyle/>
          <UnitsContainer/>
          {(environmentTypeName === "test" || environmentTypeName === "dev") && <p className="env env-left">{environmentTypeName}</p>}
          {(environmentTypeName === "test" || environmentTypeName === "dev") && <p className="env env-right">{environmentTypeName}</p>}
        </GeneralStyles>
      </Fragment>
  );
};

export default Main;
