const paymeTheme = {
    //px Padding
    padding: '20px',
    paddingLg: '30px',
    paddingMd: '15px',
    paddingSm: '10px',
    paddingXs: '8px',
    //rem Padding
    paddingRem: '1.25rem',
    paddingRemLg: '1.875rem',
    paddingRemMd: '0.9rem',
    paddingRemSm: '0.6rem',
    paddingRemXs: '0.5rem',
    //vh height
    heightXLg: '80vh',
    heightLg: '50vh',
    heightMd: '30vh',
    heightSm: '7vh',
    heightXs: '5vh',
    //vw width
    widthLg: '75vw',
    widthMd: '30vw',
    widthSm: '20vw',
    widthXs: '10vw',
    //Brand Colors
    warningColor: '#FF8A02',
    colorBlack: '#000000',
    colorWhite: '#fff',
    errorColor: '#E50000',
    successColor: '#6FBC14',
    colorDarkGray: 'grey',
    footerBackground: '#0B2D5A',
    floatColor: '#0090DF',
    lightGrey: '#D3D3D3',
    colorYellow: 'yellow',
    colorPurple: '#a302bf',
    //px Font
    fontSizeBase: '14px',
    fontSizeLg: '18px',
    fontSizeSmall: '13px',
    fontSizeXs: '10px',
    fontSizeNormal: '17px',
    fontSizeH1: '55px',
    fontSizeH2: '36px',
    fontSizeH3: '32px',
    fontSizeH4: '28px',
    fontSizeH5: '22px',
    fontSizeH6: '20px',
    //rem Font
    fontSizeRemBase: '0.9rem',
    fontSizeRemLg: '1.1rem',
    fontSizeRemSmall: '0.813rem',
    fontSizeRemXs: '0.6rem',
    fontSizeRemNormal: '1.063rem',
    fontSizeRemH1: '3.438rem',
    fontSizeRemH2: '2.25rem',
    fontSizeRemH3: '2rem',
    fontSizeRemH4: '1.75rem',
    fontSizeRemH5: '1.375rem',
    fontSizeRemH6: '1.25rem',
    fontLight: '300',
    fontRegular: '400',
    fontRegularItalic: 'italic',
    fontSemiBold: '600',
    fontBold: '700',
    fontExtraBold: '800',
    //Input
    inputHeight: '39px',
    inputRemHeight: '2.438rem'
};

export default paymeTheme;
