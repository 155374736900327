import * as React from "react"

function PlaceholderIconSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={973.1}
      height={973.1}
      viewBox="0 0 973.1 973.1"
      stroke="#0090DF"
      fill="#0090DF"
      color="#0090DF"
      {...props}
    >
      <path d="M502.29 788.199h-47c-33.1 0-60 26.9-60 60v64.9c0 33.1 26.9 60 60 60h47c33.101 0 60-26.9 60-60v-64.9c0-33.199-26.899-60-60-60zM170.89 285.8l86.7 10.8c27.5 3.4 53.6-12.4 63.5-38.3 12.5-32.7 29.9-58.5 52.2-77.3 31.601-26.6 70.9-40 117.9-40 48.7 0 87.5 12.8 116.3 38.3 28.8 25.6 43.1 56.2 43.1 92.1 0 25.8-8.1 49.4-24.3 70.8-10.5 13.6-42.8 42.2-96.7 85.9-54 43.7-89.899 83.099-107.899 118.099-18.4 35.801-24.8 75.5-26.4 115.301-1.399 34.1 25.8 62.5 60 62.5h49c31.2 0 57-23.9 59.8-54.9 2-22.299 5.7-39.199 11.301-50.699 9.399-19.701 33.699-45.701 72.699-78.1C723.59 477.8 772.79 428.4 795.891 392c23-36.3 34.6-74.8 34.6-115.5 0-73.5-31.3-138-94-193.4-62.6-55.4-147-83.1-253-83.1-100.8 0-182.1 27.3-244.1 82-52.8 46.6-84.9 101.8-96.2 165.5-3.501 18.6 9.199 36 27.699 38.3z" />
    </svg>
  )
}

export default PlaceholderIconSvg
