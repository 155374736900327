import React, {FC} from "react";
import { StyledMessage } from "./styles";

interface Props {
  messageType: string;
  messageTitle: string;
  messageBody: string;
}

const MessageNotification: FC<Props> = ({ messageType, messageTitle, messageBody }) => {

  return (
    <StyledMessage className={ messageType } theme={{hasMessageBody: messageBody !== ""}}>
      <div className="message-title">{messageTitle}</div>
      {messageBody !== "" && <div className="message-body">{messageBody}</div>}
    </StyledMessage>
  );
};

export default MessageNotification;
