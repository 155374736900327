import React, {FC, useState} from "react";
import { StyledUnitDetails } from "./styles";
import {TransactionPaymentStatusDetails, UnitDetailsInterface} from "../../@types";
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { IconButton } from "@material-ui/core";


interface Props {
  categoryUnit: string;
  paymentValuePerUnit: number;
  selectionStatus: string;
  isVending?: boolean;
  userSelection?: string;
  userSpecialButton?: string;
  photoUrl: string;
  userId?: string;
  transactionPaymentStatusDetails: TransactionPaymentStatusDetails,
  unitDetails: UnitDetailsInterface;
  isPayButtonPressed: boolean;
  onChangePaymentValueHandler: (value: string) => void;
  isLoading?: boolean;
  cashflowPaymentLink?: string;
}

const UnitDetails: FC<Props> = ({
  unitDetails,
  userId=false,
  categoryUnit,
  paymentValuePerUnit,
  selectionStatus,
  isVending,
  userSelection,
  onChangePaymentValueHandler,
  userSpecialButton='',
  photoUrl,
  transactionPaymentStatusDetails,
  isPayButtonPressed,
  isLoading,
  cashflowPaymentLink
}) => {
  const [inputError, setInputError] = useState('');
  const defaultTransactionAmount = unitDetails.defaultTransactionAmount || 1.00;
  const isHostSingleValue = unitDetails.creditType === "HOST_SING_VAL";
  const [priceValue, setPriceValue] = useState(defaultTransactionAmount);

  const addDecimals = (value: string) => {
    const priceValueTwoDecimals = `${value}`.split('.');
    if (priceValueTwoDecimals[1]) {
        if (priceValueTwoDecimals[1].length === 1) {
            return priceValueTwoDecimals[0] + '.' + priceValueTwoDecimals[1] + '0';
        } else {
            return value;
        }
    } else {
        return priceValueTwoDecimals[0] + '.00';
    }
  };

  const changeValue = (value: number) => {
    const currentValue = priceValue + value;

    if (currentValue >= defaultTransactionAmount && currentValue <= unitDetails.hostType.maxTransactionAmount) {
      onChangePaymentValueHandler(currentValue.toFixed(2).toString());
      setPriceValue(parseFloat(currentValue.toFixed(2)));
      setInputError("");
    } else if (currentValue > unitDetails.hostType.maxTransactionAmount) {
      setInputError(`Credit value has to be smaller than ${unitDetails.hostType.maxTransactionAmount}`);
    } else {
      setInputError(`Credit value has to be higher than ${defaultTransactionAmount.toFixed(2)}`);
    }
  };

  const enableChangeValueButtons = unitDetails.status === 'READY' && !userId && !isPayButtonPressed && !isVending &&
    ['success', 'warning', 'timeout', 'error', 'denied'].indexOf(transactionPaymentStatusDetails.transactionStatus) === -1 &&
    ['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1;

  return (
    <StyledUnitDetails>
      <div className="image-unit-wrapper"><img src={photoUrl} alt="unit"/></div>
      {['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1 &&
          !isVending && !userId && !isHostSingleValue && !isLoading && transactionPaymentStatusDetails.transactionStatus === '' &&
          cashflowPaymentLink === '' &&
          <div className="change-price-wrapper">
            <div className="change-price">
              <div className="change-price-functionality">
                  <IconButton onClick={() => enableChangeValueButtons && changeValue(-0.5)}>
                    <RemoveRoundedIcon htmlColor={'#0090DF'}/>
                  </IconButton>
                  <div>
                    <span>£</span>
                    <span>{priceValue && addDecimals(priceValue)}</span>
                  </div>
                  <IconButton onClick={() => enableChangeValueButtons && changeValue(0.5)}>
                    <AddRoundedIcon htmlColor={'#0090DF'}/>
                  </IconButton>
              </div>
            </div>
            {inputError && <div className="label-error">{inputError}</div>}
          </div>
        }
      <div className="unit-content-wrapper">
        {unitDetails && unitDetails.hostType && <div className="unit-category-name">{unitDetails.hostType.name}</div>}
        {['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1 && 
        !isVending && !userId && isHostSingleValue && unitDetails &&
        unitDetails.currency && unitDetails.currency.symbol && defaultTransactionAmount &&
        <div className="unit-payment-value">
          {unitDetails.currency.symbol}{defaultTransactionAmount &&
        addDecimals(defaultTransactionAmount)} play
        </div>
        }
        {['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1 &&
        isVending && !userId && (
          <div className="selection-wrapper">
            {selectionStatus && <div className="selection-status">{selectionStatus}</div>}
            {paymentValuePerUnit && userSelection &&
            <div className="selection-values">
              {userSelection}{userSpecialButton !== "" && <span>, {userSpecialButton} </span>}
              <span>{paymentValuePerUnit !== -1 && unitDetails && unitDetails.currency && unitDetails.currency.symbol &&
              `= ${unitDetails.currency.symbol}${paymentValuePerUnit}`}</span>
            </div>
            }
          </div>
          )
        }
      </div>
    </StyledUnitDetails>
  );
};

export default UnitDetails;
