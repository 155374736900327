import React, { FC } from "react";
import { StyledFooter } from "./styles";
import LogoSvgComponent from "./FooterSvgLogo";

const Footer: FC = () => {
  return (
    <StyledFooter>
      <LogoSvgComponent/>
    </StyledFooter>
  );
};

export default Footer;
