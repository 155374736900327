import React, { createContext, FC, useEffect, useState } from "react";
import {
  PaymentDetails,
  TransactionPaymentStatusDetails,
  UnitDetailsInterface
} from "../../../@types";
import {
  DENIED_ERROR_MESSAGE_BODY,
  DENIED_ERROR_MESSAGE_TITLE,
  PENDING_PAYMENT_BODY,
  PENDING_PAYMENT_TITLE,
  SUCCESS_MESSAGE_BODY,
  SUCCESS_MESSAGE_TITLE,
  SYSTEM_ERROR_TITLE,
  TIMEOUT_PAYMENT_BODY,
  TIMEOUT_PAYMENT_TITLE,
  UNIT_NOT_REGISTERED,
  VENDING_SUCCESS_MESSAGE_BODY
} from "../../../constants";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { FruitMachineFlow } from '../../FruitMachineFlow';

export const UnitIdContext = createContext('');
export const TransactionStatusDetails = createContext<TransactionPaymentStatusDetails>({
  paymentJobReference: '',
  transactionStatus: '',
  transactionStatusTitle: '',
  transactionStatusBody: '',
  transactionAmount: 0.0
});

const UnitsContainer: FC = () => {

  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionStatusTitle, setTransactionStatusTitle] = useState('');
  const [transactionStatusBody, setTransactionStatusBody] = useState('');
  const [transactionAmount, setTransactionAmount] = useState(0);
  const [paymentJobReference, setPaymentJobReference] = useState('');
  const [generalLoading, setGeneralLoading] = useState(true);

  const [unitId, setUnitId] = useState('');
  const [unitDetailsType, setUnitDetailsType] = useState('');
  const [paymentNonce, setPaymentNonce] = useState(null);
  const [cashflowPaymentLink, setCashflowPaymentLink] = useState('');
  const [userId, setUserId] = useState();
  const [unitDetails, setUnitDetails] = useState<UnitDetailsInterface>({
    errorCode: "",
    status: "",
    hostCategory: "",
    photoUrl: '',
    flowStatus: "",
    paymentProcessor: "",
    defaultTransactionAmount: 0,
    creditType: "",
    currency: {
      id: 1,
      code: "GBP",
      number: 826,
      minorUnit: 2,
      name: "Pound sterling",
      symbol: "£"
    },
    hostType: {
      hostCategory: null,
      hostTypeId: 1,
      code: "",
      maxTransactionAmount: 1,
      name: "",
    },
    vendingRefs: [],
    vendingPrices: [],
    vendingSpecialButtons: [],
  });

  const [socketConnection, setSocketConnection] = useState(null);
  const [stompClientState, setStompClientState] = useState(null);
  const [messageToSend, setMessageToSend] = useState();
  const [socketReceivedMessage, setSocketReceivedMessage] = useState();
  const [winnings, setWinnings] = useState(0.0);
  const params = new URLSearchParams(window.location.search.substring(1));
  const userIdFromUrl = params.get('userId');

  //Demo stuff
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);


  useEffect(() => {
    const urlPath = window.location.pathname;
    let id = urlPath.split('/')[1];
    userIdFromUrl && setUserId(userIdFromUrl);

    const socket = new SockJS(`${process.env.REACT_APP_SOCK_BASE_URL}`);
    const stompClient = Stomp.over(socket);
    const socketHeader = userIdFromUrl ? {userId: `${userIdFromUrl}`} : {};

    stompClient.connect(socketHeader, () => {
      stompClient.subscribe('/user/payApp/paymentResponse', (response: any) => {
        setSocketReceivedMessage(JSON.parse(response.body));
      });
      stompClient.subscribe('/user/payApp/userDetailsResponse', (response: any) => {
        onReceivedSocketUnitDetails(JSON.parse(response.body));
      });
      stompClient.subscribe('/user/payApp/winnings', (response: any) => {
        onReceivedWinningDetails(JSON.parse(response.body));
      });

      !userIdFromUrl && stompClient.send('/server/unitDetails', {}, JSON.stringify(id));
    });
    setSocketConnection(socket);
    setStompClientState(stompClient);
    setMessageToSend(id);
    setUnitId(id);
    setGeneralLoading(true);
  }, []);

  useEffect(() => {
    if (socketConnection && stompClientState && messageToSend) {
      if (unitDetails.hostCategory === 'VENDING' && messageToSend === paymentNonce &&
        ['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1) {
        console.log("UnitContainer:: Sending contactless payment for Vending Machine to /server/performPayment");
        stompClientState.send('/server/performPayment', {}, JSON.stringify(messageToSend));
        setGeneralLoading(true);
      }
    }
  }, [messageToSend, socketConnection, stompClientState, unitDetails, paymentNonce]);

  useEffect(() => {
    if (socketConnection && stompClientState && messageToSend) {
      if (unitDetails && unitDetails.hostCategory && (unitDetails.hostCategory === 'AMUSE_NON_GAMB' || unitDetails.hostCategory === 'AMUSE_GAMB') &&
        paymentNonce !== null && messageToSend === paymentNonce &&
        ['CONTACTLESS_UNAVAILABLE', 'CONNECTION_TIMEOUT', 'UNIT_NOT_REGISTERED'].indexOf(unitDetails.flowStatus) === -1) {
        console.log("UnitContainer:: Sending contactless payment for Pool Machine to /server/performPayment");
        stompClientState.send('/server/performPayment', {}, JSON.stringify(messageToSend));
        setGeneralLoading(true);
      }
    }
  }, [unitDetails, paymentNonce, messageToSend, socketConnection, stompClientState]);

  useEffect(() => {
    setUnitDetailsType(unitDetails.hostCategory);
  }, [unitDetails, paymentNonce]);

  const onReceivedSocketUnitDetails = (msg: any) => {
    console.log("UnitContainer:: (onReceivedSocketUnitDetails) Received message", msg);
    setUnitDetails(msg);
    setUnitDetailsType(msg.hostCategory);
    msg.flowStatus !== "PAYMENT_PROCESS_INITIALIZED" && setGeneralLoading(false);
    if (msg.cashflowPaymentLink && msg.cashflowPaymentLink !== '') {
      setCashflowPaymentLink(msg.cashflowPaymentLink);
    }
  };

  const onReceivedWinningDetails = (msg: any) => {
    console.log("UnitContainer:: (onReceivedWinningDetails) Received winnings", msg);
    setWinnings(parseFloat(parseFloat(msg.amount).toFixed(2)));
  };

  const onSocketPaymentStatusMessage = (msg: any) => {
    console.log("UnitContainer:: (onSocketPaymentStatusMessage) Received payment status message", msg);
    setGeneralLoading(false);
    if (userIdFromUrl) {
      setUnitDetails({
        ...unitDetails,
        hostCategory: msg.hostCategory,
        hostType: {...msg.hostType},
        photoUrl: msg.photoUrl
      });
    }
    if (msg.cashflowPaymentLink && msg.cashflowPaymentLink !== '') {
      setCashflowPaymentLink(msg.cashflowPaymentLink);
    } else if (msg.paymentStatus === "COMPLETED" || msg === "COMPLETED") {
      setTransactionStatus("success");
      setTransactionStatusTitle(SUCCESS_MESSAGE_TITLE);
      setTransactionAmount(msg.transactionAmount);
      if (unitDetailsType === "VENDING") {
        setTransactionStatusBody(VENDING_SUCCESS_MESSAGE_BODY);
      } else {
        setTransactionStatusBody(SUCCESS_MESSAGE_BODY);
      }
      return;
    } else if (msg.paymentStatus === "PENDING" || msg === "PENDING") {
      setTransactionStatus("warning");
      setPaymentJobReference(msg.paymentJobReference);
      setTransactionStatusTitle(PENDING_PAYMENT_TITLE);
      setTransactionStatusBody(PENDING_PAYMENT_BODY);
      return;
    } else if (msg.paymentStatus === "TIMEOUT" || msg === "TIMEOUT") {
      setTransactionStatus("timeout");
      setTransactionStatusTitle(TIMEOUT_PAYMENT_TITLE);
      setTransactionStatusBody(TIMEOUT_PAYMENT_BODY);
      return;
    } else if (msg.paymentStatus === "DENIED" || msg === "DENIED") {
      setTransactionStatus("denied");
      setTransactionStatusTitle(DENIED_ERROR_MESSAGE_TITLE);
      setTransactionStatusBody(DENIED_ERROR_MESSAGE_BODY);
      return;
    } else if (msg.paymentStatus === "ERROR" || msg === "ERROR") {
      setTransactionStatus("error");
      setTransactionStatusTitle(SYSTEM_ERROR_TITLE);
      return;
    }
  };

  const handleNonce = (nonce: PaymentDetails) => {
    setPaymentNonce(nonce);
    setMessageToSend(nonce);
  };


  const handlePaymentSuccess = (transactionId: string, nonce: PaymentDetails) => {
    setGeneralLoading(true);
    setPaymentSuccess(true);
    setTransactionAmount(nonce.amount);
  }

  const handlePaymentFailed = () => {
    setGeneralLoading(true);
    setPaymentFailed(true);
  }

  const handleFlowCompleted = () => {
    setTransactionStatus('');
    setTransactionAmount(0);
    setTransactionStatusTitle('');
    setTransactionStatusBody('');
  }

  const handleWinningsCollection = () => {
    setWinnings(0.0);
  }

  useEffect(() => {
    if (paymentFailed && stompClientState) {
      const paymentRequest = {
        paymentJobReference: paymentJobReference,
        paymentStatus: 'Failed'
      };
      console.log("UnitContainer:: Sending payment failed", paymentRequest);
      stompClientState.send('/server/webhook', {}, JSON.stringify(paymentRequest));
    }
  }, [paymentFailed]);

  useEffect(() => {
    if (paymentSuccess && stompClientState) {
      const paymentRequest = {
        paymentJobReference: paymentJobReference,
        paymentStatus: 'Paid'
      };
      console.log("UnitContainer:: Sending payment success", paymentRequest);
      stompClientState.send('/server/webhook', {}, JSON.stringify(paymentRequest));
    }
  }, [paymentSuccess]);

  useEffect(() => {
    if (socketReceivedMessage) {
      onSocketPaymentStatusMessage(socketReceivedMessage);
    }
  }, [socketReceivedMessage]);


  /**
   * The options menu is currently shown only for fruit machines that are registered for the demo
   */

  return (
    <UnitIdContext.Provider value={unitId}>
      <TransactionStatusDetails.Provider
        value={{transactionStatus, transactionStatusTitle, transactionStatusBody}}>
        {<FruitMachineFlow userId={userId}
                           unitId={unitId}
                           unitDetails={unitDetails}
                           winnings={winnings}
                           unitDetailsType={unitDetailsType}
                           cashflowPaymentLink={cashflowPaymentLink}
                           onPaymentFailed={handlePaymentFailed}
                           onPaymentSuccess={handlePaymentSuccess}
                           onFlowComplete={handleFlowCompleted}
                           onCollectWinnings={handleWinningsCollection}
                           generalLoading={generalLoading}
                           paymentNonce={paymentNonce}
                           paymentJobReference={paymentJobReference}
                           transactionStatus={transactionStatus}
                           transactionAmount={transactionAmount}
                           handleNonce={handleNonce}/>
        }
      </TransactionStatusDetails.Provider>
    </UnitIdContext.Provider>
  );
};

export default UnitsContainer;
